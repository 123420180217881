import { Stack } from "@mui/material"
import {
  Route,
  Routes,
} from "react-router-dom"
import { Footer } from "./components/Footer"
import Header from "./components/Header"
import Landing from "./pages/Landing"
import TempHomeForm from "./pages/TempHomeForm"
import VolunteerForm from "./pages/volunteerForm"

const Main = () =>{
  return (
  <Stack direction="column">
    <Header/>
    <div style={{flexGrow: 1}}>
      <Routes>
        <Route path='/' element={<Landing/>}/>
        <Route path='/temphome' element={<TempHomeForm/>}/>
        <Route path='/volunteer' element={<VolunteerForm/>}/>
      </Routes>
    </div>
    <Footer/>
  </Stack>
  )
}

export default Main;