import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { MAColors } from "../styles/colors";

export const MAButton = ({text, cta, variant, primaryColor = MAColors.BackgroundBlue, secondaryColor ='white'} : {
  text: string, 
  cta?: ()=>void, 
  variant?: "text" | "outlined" | "contained",
  primaryColor: string
  secondaryColor: string
}) =>{
  return (
    <StyledButton 
      onClick={cta}
      variant={variant}
      size="large"
      macolor={primaryColor}
      massecondarycolor={secondaryColor}
    >
      {text}
    </StyledButton>
  )
};

const StyledButton = styled(Button)<{macolor: string, massecondarycolor: string}>`
  ${p=> `
    background-color: ${p.macolor};
    color: ${p.massecondarycolor};
    border-color: ${p.massecondarycolor};
  `}
  margin: 16px 0px;
  :hover{
    ${p=> `
      color: ${p.macolor};
      border-color: ${p.macolor};
      background-color: ${p.massecondarycolor};
    `}
  };
`;