import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { Grid, styled } from '@mui/material';
import MALogo from '../assets/logo.svg';
import { Link, useNavigate } from 'react-router-dom';
import { MAColors } from '../styles/colors';

const pages = [{name: 'Voluntarios', url: 'volunteer'}, {name: 'Hogares temporales', url: 'temphome'}];

const ResponsiveAppBar = () => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  return (
    <AppBar position="static" sx={{padding: { xs: '8px', md: '32px' }}} style={{backgroundColor: MAColors.BackgroundBlue}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to="/" style={{textDecoration: 'none'}}>
            <Grid container color="white" marginLeft="16px"textAlign="left" columnGap={2}>
              <Grid item xs={12} md={2} style={{maxWidth: '100%'}}>
                <img src={MALogo} alt="" role="presentation" style={{maxWidth: 'inherit'}}/>
              </Grid>
              <Grid item xs={12} md={4}>
                <Title sx={{fontSize: { xs: '10px', md: '32px' }}}>MASCOTALERTA</Title>
                <SubTitle sx={{fontSize: { xs: '10px', md: '24px' }, display: { xs: 'none', md: 'flex' }}}>FUNDACIÓN</SubTitle>
              </Grid>
            </Grid>
          </Link>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'right' }}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={(event) => setAnchorElNav(event.currentTarget)}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={() => setAnchorElNav(null)}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page,index) => (
                <MenuItem onClick={() => setAnchorElNav(null)} key={index}>
                    <Link to={page.url} style={{textDecoration: 'none', color:MAColors.BackgroundBlue}} key={index}>
                      <Typography textAlign="center">{page.name}</Typography>
                    </Link>
                  </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', justifyContent: 'right' } }}>
            {pages.map((page, index) => (
                <Button
                  onClick={() => {
                    setAnchorElNav(null)
                    navigate(page.url)
                  }}
                  key={index}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page.name}
                </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;

const Title = styled('h1')`
  margin: 0;
  font-weight: 900;
`

const SubTitle = styled('h2')`
  margin: 0;
  font-weight: 400;
  letter-spacing: 1rem;
`