import styled from "@emotion/styled";
import { Grid, Stack, Typography } from "@mui/material";
import carrouselImage from "../assets/carousel.png";
import aboutUsImage from "../assets/aboutus.png";
import respectImg from "../assets/values/respect.svg";
import colabImg from "../assets/values/colaboration.svg";
import indepImg from "../assets/values/independency.svg";
import integImg from "../assets/values/integrity.svg";
import responsImg from "../assets/values/responsability.svg";
import tempHomeImg from "../assets/tempHome.png";
import { MAColors } from "../styles/colors";
import { useNavigate } from "react-router-dom";
import { MAButton } from "../components/Button";

const Landing = () =>{
  return (
    <Stack direction="column">
      <Carrousel/>
      <AboutUs/>
      <Values/>
      <TempHome/>
      <Volunteer/>
    </Stack>
  )
};

const Carrousel = () =>{
  return (
    <CarrouselContainer direction="column">
      <Typography variant="h2">¡Bienvenido a Mascotalerta!</Typography>
      <Typography variant="body1">Nos encargamos del bienestar de tu Mascota</Typography>
    </CarrouselContainer>
  )
}

const AboutUs = () =>{
  return (
    <AboutUsContainer container color={MAColors.BackgroundBlue} columnSpacing={12} justifyContent="space-evenly">
      <Grid item md={6} xs={12} alignSelf="center">
        <Typography variant="h3">
          Acerca de Nosotros...
        </Typography>
        <Typography variant="body1">
          Somos una fundación dedicada a promover la educación sobre la tenencia responsable de mascotas. Queremos ser un referente que ayude a crear conciencia en la población sobre la importancia del cuidado de estos, para así  contribuir a generar una mejor calidad de vida de los animales y personas que nos rodean.
        </Typography>
      </Grid>
      <Grid item md={4} xs={12} textAlign="center">
        <img src={aboutUsImage} role="presentation" alt=""/>
      </Grid>
    </AboutUsContainer>
  )
}

const Values = () =>{
  return (
    <ValuesContainer container color="white" alignItems="center">
      <Grid item xs={12}>
        <Typography variant="h2" fontWeight="700" marginBottom="24px">Nuestros Valores</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
          <Value text="Respeto" image={respectImg}/>
      </Grid>
      <Grid item xs={12} md={4}>
          <Value text="Responsabilidad" image={responsImg}/>
      </Grid>
      <Grid item xs={12} md={4}>
          <Value text="Integridad" image={integImg}/>
      </Grid>
      <Grid item xs={12} md={4}>
          <Value text="Colaboración" image={colabImg}/>
      </Grid>
      <Grid item xs={12} md={4}>
          <Value text="Independencia" image={indepImg}/>
      </Grid>
    </ValuesContainer>
  )
}

const Value = ({image, text}: {image: string, text: string}) => {
  return (
    <ValuesContainer container direction="column">
      <img src={image} alt="" role="presentation"/>
      <Typography variant="body1">{text}</Typography>
    </ValuesContainer>
  )
}

const TempHome = () =>{
  const navigate = useNavigate();
  return (
    <TempHomeContainer container>
      <Grid item xs={12}>
        <Typography variant="h2">Se parte de nuestra red de<br/>Hogares Temporales.</Typography>
      </Grid>
      <Grid item xs={12}>
        <MAButton
          text="Ver más"
          variant="outlined"
          primaryColor="white"
          cta={()=> navigate('/temphome')}
          secondaryColor={MAColors.BackgroundBlue}
        />
      </Grid>
    </TempHomeContainer>
  )
}

const Volunteer = () =>{
  const navigate = useNavigate()
  return (
    <VolunteerContainer direction="column">
      <Typography variant="h2">¿Quieres participar con nosotros?</Typography>
      <div>
        <MAButton
          text="Ingresa Aquí"
          variant="outlined"
          primaryColor="white"
          cta={()=> navigate('/volunteer')}
          secondaryColor={MAColors.Gold}
        />
      </div>
    </VolunteerContainer>
  )
}

export default Landing;

const CarrouselContainer = styled(Stack)`
  color: white;
  background-image: url(${carrouselImage});
  background-size: cover;
  background-position: center;
  height: 50vh;
  justify-content: center;
`;

const AboutUsContainer = styled(Grid)`
  padding: 62px;
  align-self: center;
  text-align: left;
  img{
    border-radius: 16px;
    max-width: 60vw;
  }
`;

const ValuesContainer = styled(Grid)`
  background-color: ${MAColors.BackgroundBlue};
  justify-content: center;
  padding: 24px;
`;

const TempHomeContainer = styled(Grid)`
  color: white;
  background-image: url(${tempHomeImg});
  background-position: center;
  min-height: 40vh;
  justify-content: center;
  text-align: left;
  padding: 64px;
  margin-top: 24px;
`;

const VolunteerContainer = styled(Stack)`
  margin-top: 24px;
  color: white;
  background-color: ${MAColors.Gold};
  min-height: 20vh;
  justify-content: center;
  text-align: left;
  padding: 64px; 
`;