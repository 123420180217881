import {
  BrowserRouter as Router,
} from "react-router-dom";
import Main from './Main';

function App() {
  return (
    <div className="App" style={{textAlign: "center"}}>
      <Router> 
        <Main/>
      </Router>
    </div>
  );
}

export default App;
