import { Grid, Typography } from "@mui/material";
import whatsapp from "../assets/whatsapp.svg";
import instagram from "../assets/instagram.svg";
import facebook from "../assets/facebook.svg";
import twitter from "../assets/twitter.svg";
import { Link } from "react-router-dom";
import { MAColors } from "../styles/colors";


export const Footer = () =>{
  return (
    <Grid container style={{backgroundColor: MAColors.BackgroundBlue, padding: '64px'}} role="contentinfo">
      <Grid item xs={12} md={8} alignSelf="center" textAlign="left">
        <div style={{width: 'fit-content'}}>
          <Link to="/" style={{textDecoration: 'none', color: 'white'}}>
            <Typography variant="body1">
            Mascotalerta.org
            </Typography>
          </Link>
        </div>
      </Grid>
      <Grid item xs={12} md={4} textAlign="left">
        <Grid container columnSpacing={1}>
          <Grid item xs={3} style={{maxWidth: '100%'}}>
            <Link to="https://api.whatsapp.com/send?phone=56940585508&text=Hola%20Mascotalerta!%20Me%20pueden%20ayudar%3F" style={{maxWidth: 'inherit'}} target="_blank">
              <img src={whatsapp} alt="whatsapp" style={{maxWidth: 'inherit'}}/>
            </Link>
          </Grid>
          <Grid item xs={3} style={{maxWidth: '100%'}}>
            <Link to="https://www.instagram.com/mascotalerta/" style={{maxWidth: 'inherit'}} target="_blank">
              <img src={instagram} alt="instagram" style={{maxWidth: 'inherit'}}/>
            </Link>
          </Grid>
          <Grid item xs={3} style={{maxWidth: '100%'}}>
            <Link to="https://www.facebook.com/mascotalerta.cl/" style={{maxWidth: 'inherit'}} target="_blank">
              <img src={facebook} alt="facebook" style={{maxWidth: 'inherit'}}/>
            </Link>
          </Grid>
          <Grid item xs={3} style={{maxWidth: '100%'}}>
            <Link to="https://twitter.com/mascotalerta" style={{maxWidth: 'inherit'}} target="_blank">
              <img src={twitter} alt="twitter" style={{maxWidth: 'inherit'}}/>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
};